import { Pipe, PipeTransform } from '@angular/core';
import { FormControl } from '@angular/forms';

@Pipe({
  name: 'errorTextParser',
  standalone: true,
  pure: false,
})
export class ErrorTextParserPipe implements PipeTransform {
  transform(
    errors: {
      key: string;
      value: string;
    }[] | undefined,
    control: FormControl,
  ): string | null {
    let error: string | null = null;
    errors?.forEach((e) => {
      if (control.errors?.[e.key]) {
        error = e.value;
      }
    });
    return error;
  }
}
